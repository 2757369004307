.app__hello {
  font-size: 4rem;
  margin: 0;
  padding: 0 0.5rem;
  color: #61dafb;
}

.section {
  box-sizing: border-box;
  padding: 2rem;
  min-height: 20vh;
}

.section_addons {
  min-height: 30vh;
  background-color: #00bbff;
  color: #fff;
}

.addon {
  box-sizing: border-box;
  padding: 2rem;
  max-width: 1080px;
  margin: 0 auto;
}

.addon__catchy {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addon__header-title {
  position: relative;
  margin: 0;
  display: inline-block;
  padding-bottom: 0.2rem;
}
.addon__header-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  opacity: 0.7;
  transform: scaleX(0.5);
}

.addon__link_hidden {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  line-height: 0;
  font-size: 0;
  color: transparent;
}

.addon__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
}

.addon__description {
  margin: 0;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.addon_ctf .addon__description {
  padding-bottom: 2rem;
}

.addon__cta {
  background-color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1rem 1.4rem;
  color: #00bbff;
  border-radius: 6px;
}

@media only screen and (min-width: 667px) {
  .addon {
    display: flex;
    justify-content: space-evenly;
  }
}
