.hero {
  position: relative;
}

.hero::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10vh;
  width: 100%;
  height: 20vh;
  background-color: #282c34;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}

.hero__content {
  min-height: 70vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero__pad {
  padding-top: 10vh;
}
